import { useEffect, useState } from "react";
import { Navigate } from "react-router";
import styled from "styled-components";
import Card from "../../common/components/Card/Card";
import { Modal } from "../../common/components/Modal";
import { Items } from "../../common/components/NPF/NPFItems";
import { Container, ContentContainer, Row } from "../../common/components/WebLayoutComponents";
import { MobileBreakpoint } from "../../config/constants";
import { Api } from "../../services/Api";
import SmartLocker from "../../static/img/locker-open-with-boxes-one-box-trasparent.png";
import NPFLogo from "../../static/img/NPF-Logo.png";
import { PropTypes } from "prop-types";
import { boothNumber, currentYear } from "./NPFVars";
import { NPF_URL } from "../../config/pages";

const TitleRow = styled(Row)`
    .title {
        max-width: fit-content;
        font-size: 44px;
        color: #1d1d52;
        margin-bottom: 40px;
    }

    .npf-logo {
        padding: 0;
        height: 140px;
        width: 170px;
        margin-bottom: 30px;
        margin-left: auto;
    }

    @media screen and (max-width: ${MobileBreakpoint}) {
        flex-direction: column-reverse;
        margin-bottom: 30px;

        .npf-logo {
            margin: auto auto 30px auto;
        }

        .title {
            font-size: 32px;
        }
    }
`;

const NPFContestPageProps = {
   tradeShow: PropTypes.array,
};

const NPFContestPage = props => {
   const api = new Api();
   api.setup();

   const [submitError, setSubmitError] = useState(false);
   const [repeatEntry, setRepeatEntry] = useState(false);
   const [screenSize, setScreenSize] = useState(getCurrentDimension());

   function getCurrentDimension() {
      return {
         width: window.innerWidth,
         height: window.innerHeight,
      };
   }

   useEffect(() => {
      const updateDimension = () => {
         setScreenSize(getCurrentDimension());
      };
      window.addEventListener("resize", updateDimension);

      return () => {
         window.removeEventListener("resize", updateDimension);
      };
   }, [screenSize]);

   if (props.tradeShow[0]["contest-flag"]) {
      return (
         <Container>
            <Modal modalActive={submitError} closeFunction={() => setSubmitError(false)}>
               <h1>Hmm...</h1>
               <p>Looks like something went wrong, please try again.</p>
               <button onClick={() => setSubmitError(false)}>Close</button>
            </Modal>
            <Modal modalActive={repeatEntry} closeFunction={() => setRepeatEntry(false)}>
               <h1>Already Submitted</h1>
               <p>This contest only allows one entry per email.</p>
               <button onClick={() => setRepeatEntry(false)}>Close</button>
            </Modal>
            <ContentContainer className="medium-pb">
               <TitleRow>
                  <h1 style={{ margin: "auto 0" }} className="title">
                     Unlock a New Delivery Method: Experience USPS Smart Lockers
                  </h1>
                  <img
                     src={NPFLogo}
                     alt="Logo for the National Postal Forum."
                     className="left-desktop-center-mobile npf-logo"
                  />
               </TitleRow>
               <h2 style={{ marginBottom: "25px" }}>Welcome to NPF {currentYear} – we’re so glad you’re here!</h2>
               <p className="small-mb">
                  Come visit us at booth #{boothNumber} and learn how Smart Lockers can provide your customers with a free,
                  secure and convenient alternative to home delivery. We have some exciting giveaways that you can
                  retrieve directly from the Smart Locker at the booth.
               </p>
               <span className="medium-mb" style={{ fontSize: "24px", marginBottom: "35px" }}>
                  USPS Mailman Rubber Duck
               </span>
               <Row>
                  {Items?.map(item => {
                     return (
                        <Card
                           key={item.id}
                           src={item.source}
                           alt={item.alt}
                           title={item.title}
                           price={item.price}
                           link={NPF_URL + "/cart"}
                           item={item}
                           variant="product"
                        />
                     );
                  })}
                  <img
                     src={SmartLocker}
                     alt="A USPS Smart Locker with one door open and a package sitting in front of it; package pickup just got simpler."
                     style={{ marginLeft: "auto" }}
                  />
               </Row>
            </ContentContainer>
         </Container>
      );
   } else return <Navigate replace to={"/"} />;
};

NPFContestPage.propTypes = NPFContestPageProps;

export default NPFContestPage;
