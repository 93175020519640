import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "@informed-innovations/components";

const CardProps = {
   src: PropTypes.string,
   alt: PropTypes.string,
   link: PropTypes.string,
   title: PropTypes.string,
   price: PropTypes.string,
   disableActions: PropTypes.bool,
   item: PropTypes.object,
   variant: PropTypes.oneOf(["product", "standard"]),
   content: PropTypes.element,
};

const CardStyled = styled.li`
    max-width: 400px;
    padding: 1rem;
    list-style-type: none;
    height: fit-content;

    ${({ variant }) =>
            variant === "product" &&
            `
    &:hover {
      background: #fff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.29);

      & > div {
        opacity: 1;
      }
    }
  `}

    ${({ variant }) =>
            variant === "standard" &&
            `
      background: #fff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.29);
  `}
`;

const CardContent = styled.div`
    color: #000000;
    display: block;
    height: fit-content;

    img {
        width: 100%;
    }

    .title {
        margin: 1rem 0;
        display: block;
    }

    p {
        color: inherit;
    }
`;

const CardTitle = styled.div`
    display: flex;
    background-color: #6eada8;
    color: #fff;
    margin: -1rem -1rem 1rem -1rem;
    padding: 1rem;
    font-family: "Helvetica Neue Bold";
    justify-content: center;
`;

const Card = ({ src, alt, link, title, price, item, disableActions = false, variant = "standard", content }) => {
   const onProductClick = item => {
      sessionStorage.setItem("npf-item", JSON.stringify(item));
   };

   if (variant === "standard") {
      return (
         <CardStyled variant={variant}>
            {title && <CardTitle>{title}</CardTitle>}
            <CardContent>{content}</CardContent>
         </CardStyled>
      );
   }
   if (variant === "product") {
      if (!src || !alt || !link || !price) {
         return <div>You are missing needed props to render a product card</div>;
      }

      return (
         <CardStyled variant={variant} onClick={() => onProductClick(item)}>
            <CardContent>
               <Link to={link}>
                  <img src={src} alt={alt} style={{ maxHeight: "241px"}} />
               </Link>
               {title && (<Link className="title" to={link}>
                  {title}
               </Link>)}
               <p>
                  <strong>{price}</strong>
               </p>
               <Link to={link}>
                  <Button style={{ width: "100%" }}>Add to Cart</Button>
               </Link>
            </CardContent>
         </CardStyled>
      );
   }
};

Card.propTypes = CardProps;

export default Card;
