import { currentYear } from "../features/contest-page/NPFVars";

export const CUSTOMER_TAB_NAME = "Package Recipients";

export const CUSTOMER_PAGE_URL = "/package-recipients";

export const MERCHANT_TAB_NAME = "Package Shippers";

export const MERCHANT_PAGE_URL = "/package-shippers";

export const BUSINESS_TAB_NAME = "Business";

export const BUSINESS_PAGE_URL = "/business";

export const LOCKER_LOCATION_PAGE_URL = "/locker-location";

export const FAQ_PAGE_URL = "/faq";

export const CONTEST_CLOSED_URL = "/contest-closed";

export const NPF_TC_URL = "/npf-terms-and-conditions";

export const NPF_URL = "/npf" + currentYear;

export const RETAILERS_URL = "/retailers";

export const REDIRECT_URL = "/redirect"
